import * as React from 'react';
import { useState } from 'react';
import { Card, CardContent, Typography, Box, Divider, Button } from '@mui/material';
import AddCardIcon from '@mui/icons-material/AddCard';
import LockIcon from '@mui/icons-material/Lock'; // Import the Lock icon
import CardPaymentInfoModal from '../CardPaymentInfoModal/CardPaymentInfoModal'; // Ensure this path is correct based on your project structure

function OnlinePayment() {
  const [modalOpen, setModalOpen] = useState(false);

  const handlePayOnline = () => {
    window.open('https://book.stripe.com/cN2bMu5m441p5va9AA', '_blank'); // Redirects to the Stripe link in a new tab
  };

  const handleHowToPay = () => {
    setModalOpen(true); // Open the Card Payment Info Modal
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the Modal
  };

  return (
    <Card sx={{ width: '90%', margin: '40px auto', padding: '0px', borderRadius: 2 }}>
      <CardContent>
        <p>
          Най-бързият и сигурен начин да завършите вашата резервация онлайн, без излишни стъпки.
        </p>

        <Divider sx={{ marginTop: 1, marginBottom: 5, backgroundColor: 'primary.main', height: 2 }} />

        {/* Pay Online Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 3 }}>
          <Button
            variant="contained"
            onClick={handlePayOnline}
            fullWidth
            startIcon={<AddCardIcon />}
            sx={{
              marginBottom: 4,
              paddingY: 2,
              borderRadius: 8,
              fontWeight: 700,
            }} // Added margin bottom for spacing
          >
            Плати онлайн
          </Button>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        {/* Section with Icon, Text, and Stripe Logo */}
  
        {/* Payment Logos Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: 0,
            gap: 0,
            flexWrap: 'wrap',
            marginBottom: 1
          }}
        >
          <img src="/visa_logo.svg" alt="Visa" style={{ width: 40, height: 'auto' }} />
          <img src="/mastercard_logo.svg" alt="MasterCard" style={{ width: 40, height: 'auto' }} />
          <img src="/googlepay_logo.svg" alt="Google Pay" style={{ width: 40, height: 'auto' }} />
          <img src="/applepay_loog.svg" alt="Apple Pay" style={{ width: 40, height: 'auto' }} />
          <img src="/paypal_logo.svg" alt="PayPal" style={{ width: 40, height: 'auto' }} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: 0,

          }}
        >
          {/* Lock Icon and Text */}
          <Box sx={{ display: 'flex', flex: 1 }}>
           
            <Typography
              variant="body2"
              sx={{
                color: 'success.main',
                fontSize: '0.8rem',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                marginBottom: 1
              }}
            >
              Guaranteed safe & secure payment
            </Typography>
          </Box>

          {/* Stripe Logo */}
          <Box sx={{ textAlign: 'right', flex: 1 }}>
            <img src="/stripe.svg" alt="Stripe" style={{ height: 25 }} />
          </Box>
        </Box>


        <Divider sx={{ marginY: 2 }} />

        {/* How to Pay Online Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 0 }}>
          <Button variant="outlined" color="primary" sx={{ fontSize: '0.6rem' }} onClick={handleHowToPay}>
            Как да платя онлайн ?
          </Button>
        </Box>

        {/* Card Payment Info Modal */}
        <CardPaymentInfoModal open={modalOpen} handleClose={handleCloseModal} />
      </CardContent>
    </Card>
  );
}

export default OnlinePayment;
